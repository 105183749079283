.bottomBorder {
    border-bottom: solid 0.5px rgba(97, 102, 179, 0.4) !important;
}

.leftBorder {
    border-left: solid 0.5px rgba(97, 102, 179, 0.4) !important;
}

.rightBorder {
    border-right: solid 0.5px rgba(97, 102, 179, 0.4) !important;
}

.allBorders {
    border: solid 0.5px rgba(97, 102, 179, 0.4) !important;
}

@media (prefers-color-scheme: dark) {
    .bottomBorder {
        border-bottom: solid 0.5px rgba(97, 102, 200, 0.4) !important;
    }

    .leftBorder {
        border-left: solid 0.5px rgba(97, 102, 200, 0.4) !important;
    }

    .rightBorder {
        border-right: solid 0.5px rgba(97, 102, 200, 0.4) !important;
    }

    .allBorders {
        border: solid 0.5px rgba(97, 102, 200, 0.4) !important;
    }
}