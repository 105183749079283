/* Titlebar Styles */
.title-bar {
    width: calc(100% - 10px) !important;
    margin: 0;
    padding: 0px 5px;
    position: fixed;
    top: 0;
    height: 37px;
    z-index: 900 !important;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    border-bottom: solid;
}

.mac-overlay {
    width: 65px;
}

.title-bar .title {
    padding: 8px 0;
    margin: 0;
    height: 16px;
    flex-grow: 1;
}

.title-container {
    margin: 0;
    padding: 0;
    width: calc(100% - 6px);
}

@media (prefers-color-scheme: dark) {
    .title-bar {
        box-shadow: none;
        background: #1a1a1a;
    }
}