.purple {
    background-color: rgba(97, 102, 179, 0.8) !important;
}

.purple.dark {
    background-color: rgba(127, 152, 220, 0.8) !important;
}

.purple-color {
    color: rgba(97, 102, 179, 0.9) !important;
}

a.purple-color {
    color: rgba(97, 102, 179, 1) !important;
}

.purple-color.dark {
    color: rgba(127, 152, 220, 0.9) !important;
}
  
.purple-color-tint {
    color: rgba(97, 102, 179, 0.7) !important;
}
  
.purple-color-tint.dark {
    color: rgba(51, 91, 201, 0.7) !important;
}

.purple-border {
    border-color: rgba(97, 102, 179, 0.8) !important;
}
  
.purple-view {
    background-color: rgba(97, 102, 179, 0.1);
}

.purple-sidebar {
    background: linear-gradient(0deg, rgba(97, 102, 179, 0.2), rgba(97, 102, 179, 0.2)), #FFFFFF;
}

.purple-view.dark, .purple-sidebar.dark {
    background-color: rgba(97, 102, 200, 0.3);
}

/* Buttons */
.purple-button {
    background: transparent;
}

.purple-button:hover {
    background-color: rgba(97, 102, 179, 0.1);
}

.purple-filled-button {
    background-color: rgba(97, 102, 179, 0.9);
}
  
.purple-filled-button * {
    transition-duration: 0s !important;
    transition-delay: 0s !important;
}
  
.purple-filled-button:hover {
    background: rgba(97, 102, 179, 0.8);
}

.purple-sbar-current {
    background-color: rgba(97, 102, 179, 0.8);
}

.purple-color.sbar-hoverable:hover,.purple-color .vh-hoverable:hover {
    background-color: rgba(97, 102, 179, 0.1);
}

.purple-color.recent-block:hover {
    background-color: rgba(97, 102, 179, 8%);
}

@media (prefers-color-scheme: dark) {
    .purple-button:hover {
        background-color: rgba(97, 102, 200, 0.2);
    }

    .purple-view {
        background-color: rgba(97, 102, 200, 0.3) !important;
    }

    .purple-sidebar {
        background: linear-gradient(0deg, rgba(97, 102, 200, 0.3), rgba(97, 102, 200, 0.3)), #111;
    }

    .purple {
        background-color: rgba(105, 131, 203, 0.8) !important;
    }

    .purple-color {
        color: rgba(127, 152, 220, 0.9) !important;
    }

    a.purple-color {
        color: rgba(127, 152, 220, 1) !important;
    }

    .purple-color-tint {
        color: rgba(127, 152, 220, 0.7) !important;
    }

    .purple-sbar-current {
        background-color: rgba(105, 131, 203, 0.8);
    }

    .purple-color.recent-block:hover {
        background-color: rgba(127, 152, 220, 8%);
    }

    .purple-filled-button {
        background-color: rgba(127, 152, 220, 0.9) !important;
    }
}