/* Global Styles */
html {
  overflow: hidden;
  background: #fff;
}

.full-screen {
  height: calc(100vh - 38px);
  width: 100vw;
  padding-top: 38px;
  display: flex;
}

* {
  font-family: 'Noto Sans';
  font-size: 15px;
  transition-duration: 0.6s;
  transition-delay: 0.1s;
}

.app {
  text-align: center;
  margin: 0;
  position: fixed;
  width: 100%;
}

a {
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: 700 !important;
}


.no-select {
  -webkit-user-select: none;
  user-select: none;
}

.drag {
  -webkit-app-region: drag;
}

.remove-webkit {
  -webkit-appearance: none;
}

.solid {
  border: solid 1px;
}

button {
  cursor: pointer;
}

/* ToS Styles */
.tos {
  max-width: 1000px;
  padding: 25px 15px;
  margin: 0 auto;
  text-align: left;
  position: relative;
}

.tos * {
  font-size: 18px;
  color: #6166B3;
  margin: 6px 0;
}

.tos h1 {
  font-size: 30px;
  margin: 5px 0 10px 0;
}

.tos h2 {
  font-size: 24px;
  margin: 20px 0 5px 0;
}

.tos a {
  text-decoration: underline;
}

/* Dropdown styles */
/* Item hover styles */

.dd-item:hover, .toggle-container, .textbox {
  background: #f5f5f5 ;
}

.dark .dd-item:hover, .dark .toggle-container, .dark .textbox {
  background: rgba(255, 255, 255, 0.1);
}

/* Divider styles */
.divider {
  width: 100%;
  opacity: 0.5;
  border: none;
  border-top: 1px solid;
  height: 0 !important;
  margin: 4px 0 !important;
}

.capitalize {
  text-transform: capitalize;
}

.auto-gap {
  gap: 50%;
}

/* Spacing Styles */
/* .prefix-spaced-suffix: {margin: prefix suffix;} */

.spaced {
  margin: 10px 20px;
}

.flat-spaced {
  margin: 10px;
}

.small-spaced {
  margin: 10px 5px;
}

.spaced-small {
  margin: 5px 20px;
}

.dropdown-spaced {
  margin: 4px 4px;
}

.small-spaced-none {
  margin: 0 5px !important;
}

.small-spaced-small {
  margin: 5px 2.5px !important;
}

.none-spaced-small {
  margin: 5px 0;
}

.none-spaced-none {
  margin: 0 !important;
}
.uneven-spaced {
  margin: 2.5px 5px 6px 5px !important;
}

/* Flex Format */
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.align-center {
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.fill-space {
  flex-grow: 1;
}

.no-fill-space {
  flex-grow: 0 !important;
}

/* Color Styles */
/* Light */
.white {
  background: #fff;
}

.white-color {
  color: #fff;
}

.white-border {
  border-color: #fff;
}

/* Dark */
.dark.white {
  background: #111;
}

.dark.white-color {
  color: #000 !important;
}

.dark.white-border {
  border-color: #000;
}

/* Green */
.green {
  background: rgba(150, 199, 193, 1) !important;
}

.element {
  background: transparent !important;
  resize: none !important;
  height: 16px;
}

.element:hover, .element:focus {
  background: rgba(150, 199, 193, 0.1) !important;
}

.green-color {
  color: rgba(150, 199, 193, 1) !important;
}

.green-color-tint {
  color: rgba(150, 199, 193, 0.7) !important;
}

.green-border {
  border-color: rgba(150, 199, 193, 1) !important;
}

.green-view, .green-sidebar {
  background-color: rgba(150, 199, 193, 0.2);
}

.green-view.dark, .green-sidebar.dark {
  background-color: rgba(150, 199, 193, 0.5);
}

.green-sidebar.sidebar-item:hover {
  background-color: rgba(150, 199, 193, 0.8);
}

.button.green-button {
  background: transparent;
}

.button.green-button:hover {
  background-color: rgba(150, 199, 193, 0.2);
}

.white ::selection {
  color: #fff;
  background: rgba(97, 102, 179, 0.65);
}

.dark.white ::selection {
  color: #000;
  background: rgba(127, 152, 220, 0.65);
}

/* Buttons */
.white-button {
  background: transparent;
}

.white-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Brown */
.brown {
  background: rgba(208, 202, 178, 1);
}

.brown-color {
  color: rgba(208, 202, 178, 1);
}

.brown-border {
  border-color: rgba(208, 202, 178, 1) !important;
}

.brown-view {
  background-color: rgba(208, 202, 178, 0.2);
}

.brown-sidebar {
  background-color: rgba(208, 202, 178, 0.5);
}

/* Transparent */
.transparent {
  background: transparent;
  border: none;
}

.no-border {
  border: none !important;
}

/* Heading Formatting */
.heading {
  font-weight:400;
  margin: 5px 0;
}

.heading.small {
  font-size: 26px;
}

/* Push */
.extra-push {
  top: -8px;
  right: 0px;
}

.extra-push-lite {
  top: 28px;
  right: 0px;
}

.not-top-layer {
  z-index: 10 !important;
}

.fixed {
  position: fixed !important;
}

.relative {
  position: relative;
}

/* Element Formatting */
.container {
  margin: 20px;
}

.box {
  padding: 7px 12px;
  margin: 8px;
  width: 300px;
  position: relative;
}

/* Text Box Formatting */
.text-box {
  padding: 5px 8px;
  max-width: 450px;
  width: calc(100% - 26px);
  height: 30px;
  margin: 10px 5px;
  border: none;
}

.inner-text-box {
  flex-grow: 1;
  height: 30px;
  padding: 0;
}

/* Toggle Styles */
input:focus, select:focus, textarea:focus, .react-toggle:focus {
  outline: none !important;
}

.toggle {
  color: #fff;
}

.dark .toggle {
  color: #000;
}

.react-toggle-track-x, .react-toggle-track-check {
  top: 10px !important;
}

.react-toggle-track-x {
  right: 12px !important;
}

.react-toggle-track-check {
  left: 7px !important;
}

.react-toggle-track {
  background: #000;
}

/* Preview Styles */
.preview {
  height: 320px;
}

/* Label Styles */
.label {
  padding: 5px 7px;
}

.label.dropdown {
  padding: 4px 10px !important;
}

/* Button Styles */
.button {
  padding: 0 7.5px !important;
  height: 28px;
  min-width: 20px;
  font-size: 15px;
  border: solid 1px;
}

.button.standard {
  padding: 5px 12px !important;
  height: 38px;
  min-width: 38px;
}

/* Menu Transition Styles */
.menu .button, .menu .button *, .sidebar-item {
  transition-delay: 0s !important;
}

.no-animation {
  transition-duration: 0s !important;
  transition-delay: 0s !important;
}

.no-delay {
  transition-delay: 0s !important;
}

.menu .button, .sidebar-item {
  transition-duration: 0.3s !important;
}

.menu .button *, .script-view *, .sidebar-item * {
  transition-duration: 0s !important;
}

/* Button Size Styles */
.button.medium {
  padding: 0 12px !important;
}

.button.small {
  padding: 0 6px !important;
}

.button.short {
  height: 30px;
}

.button.dropdown {
  min-width: 200px;
}

/* Rounding Styles */
.round-5px {
  border-radius: 5px;
}

.round-10px {
  border-radius: 10px;
}

.round-15px {
  border-radius: 15px;
}

/* Alignment Classes */
.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

/* Push Element */
.absolute {
  position: absolute;
}

.top-layer {
  z-index: 10;
}

.absolute.push-left {
  left: 10px;
}

.absolute.push-right {
  right: 2.5px;
}

.absolute.semi-push-right {
  right: 10px;
}

.absolute.label {
  right: 8px;
}

.absolute.push-up {
  top: 10px;
}

.absolute.semi-push-up {
  top: 20px;
}

.absolute.no-push-up {
  top: 45px;
}

.absolute.push-up-medium {
  top: 7px;
}

.absolute.push-up-small {
  top: 4px;
}


/* Script Styles */
/* Basic Styles */
.main-view, .sidebar {
  overflow: scroll !important;
}

.script-container {
  max-width: 570px;
  margin: 20px auto 100px auto;
}

.full-fixed {
  width: 100%;
  position: fixed;
}

.cards-menu {
  max-width: 1000px;
  width: calc(100% - 30px);
  margin: 0 auto;
  padding: 8px 15px;
}

.cards-container {
  max-width: 1000px;
  width: calc(100% - 30px);
  margin: 0 auto;
  padding: 50px 15px 150px 15px;
  flex-wrap: wrap;
}

.cards-container .card {
  width: calc(33.3% - 20px);
}

.grow {
  flex-grow: 1;
}

.no-grow {
  flex-grow: 0;
}

.page-view {
  margin: 0;
  min-height: calc(100vh - 150px);
  padding-bottom: 50px;
}

.script-view {
  outline: none;
  font-family: 'Courier Prime', monospace !important;
}

.script-view * {
  font-family: 'Courier Prime', monospace !important;
  font-size: 16px;
  line-height: 1.1em;
  margin: 8px 0;
  display: block;
  width: 100%;
  border: none;
  outline: none;
}

/* Case styles */
.uppercase {
  text-transform: uppercase;
}

/* Alignment Styles */
.element.transition {
  width: 550px;
  margin: 16px 0 !important;
  text-align: right;
}

.script-view {
  text-align: left;
}

.element.heading {
  margin: 20px 0 8px 0 !important;
}

.element.dialogue {
  width: 340px;
  margin: 0 80px 16px 100px !important;
}

.element.character {
  width: 220px;
  margin: 16px 200px 0 200px !important;
}

.element.parenthetical {
  width: 280px;
  margin: 0 140px;
}

.create {
  margin: 20px;
}

.size-14 {
  font-size: 14px;
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  html, body {
    background: #121212;
  }

  .green-view, .green-sidebar {
    background-color: rgba(150, 199, 193, 0.5);
  }

  .white {
    background: #121212;
  }
  
  .white-button:hover {
    background-color: rgba(17, 17, 17, 0.1);
  }
  
  .white-border {
    border-color: #000;
  }

  .dd-item:hover, .toggle-container, .textbox {
    background: rgba(255, 255, 255, 0.1);
  }

  .toggle {
    color: #000;
  }

  .white ::selection {
    color: #000;
    background: rgba(127, 152, 220, 0.65);
  }
  
  .green-color {
    color: rgba(150, 199, 193, 1) !important;
  }

  .green-color-tint {
    color: rgba(150, 199, 193, 0.7) !important;
  }

  .white-color {
    color: #111 !important;
  }
}

@media screen and (max-width: 860px) {
  .cards-container .card {
    width: calc(50% - 20px);
  }

  .text-box {
    max-width: 400px;
  }
}

@media screen and (max-width: 800px) {
  .mob-hide {
    display: none;
  }
  .mob-col {
    flex-direction: column;
  }
  .container {
    width: calc(100% - 20px);
    margin: 20px 10px;
  }
  .create {
    margin: 18px;
  }
}

@media screen and (max-width: 560px) {
  .ext-mob-hide {
    display: none;
  }

  .cards-container .card {
    width: calc(100% - 20px);
  }

  .text-box {
    max-width: 500px;
  }

  .page-view {
    padding-bottom: 150px;
  }

  .box {
    margin: 8px 5px;
    width: calc(100% - 34px);
  }

  .title-bar .heading {
    display: none;
  }

  .container {
    width: calc(100% - 5px);
    margin: 20px 2.5px;
  }

  .create {
    margin: 12px 10px;
  }
}